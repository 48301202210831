import React from 'react'
import styles from '../../styles/Home.module.css'
import { Container, Row, Col } from 'react-bootstrap'
import Comma from '../../../public/svgs/Comma.svg'
import Reviews from '../../../public/svgs/5stars.svg'
import { testimonialList } from '../../data/testimonial'
import Marquee from 'react-fast-marquee'

const Testimonial = () => {
  return (
    <div style={{backgroundColor:"#f8f9fa"}}>
      <Container className={styles.testimonialContainerParent} id="testimonialNavigation">
        <div style={{paddingTop:10}}>
          <h2 className={styles.heading} style={{}} >Client Testimonials</h2>
        </div>
        <div className={styles.testimonialContainer}>
          <Row>
            <Marquee pauseOnHover={true} speed={40}>
              {testimonialList?.map((item, i) => {
                return (
                  <div className={styles.TestimonialTab}>
                    <Comma />
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', marginTop: 50 }}>
                      <h3 style={{ fontWeight: '500', marginRight: 10 }}>{item.name}</h3>
                      <Reviews />
                    </div>
                    <p>{item.testimonial}</p>
                  </div>
                )
              })}
            </Marquee>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default React.memo(Testimonial)
